import { useMemo, useState } from "react";
import { Button, HStack, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ConfirmModal } from "./confirmModal";
import { useSearchParams } from "react-router-dom";
import { IconArrowsDiagonal2, IconArrowsDiagonalMinimize } from "@tabler/icons-react";

export function EditModal<T>({
  caption,
  value,
  onOk,
  size,
  onCancel,
  withFullscreenSwitch,
  children,
}: {
  caption: string | JSX.Element;
  value: T;
    withFullscreenSwitch?: boolean;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  onOk: (value: T) => void;
  onCancel: () => void;
  children: (
    value: T,
    onChange: (newVal: T) => any,
    hookOnOk?: (callback: () => any) => any
  ) => JSX.Element;
}) {
  const [editedValue, setEditedValue] = useState(value);
  const [confirmModal, setConfirmModal] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  



  const noModal = useMemo(() => !!searchParams.get("no-modal"), [searchParams]);

  function testModified() {
    return JSON.stringify(editedValue) != JSON.stringify(value);
  }

  const [maximized, setMaximized] = useState(false);
  return (
    <Modal
      isCentered
      autoFocus={false}

      size={maximized ? "full" : (noModal ? "full" : (size || ["full", "full", "full", "5xl"]))}
      isOpen={true}
      onClose={() => {
        if (testModified())
          setConfirmModal(
            <ConfirmModal
              caption="Confirm"
              question="You have unsaved changes. Do you want to discard them?"
              colorScheme="orange"
              okButtonLabel="Discard"
              onOk={() => onCancel()}
              onCancel={() => setConfirmModal(null)}
            />
          );
        else onCancel();
      }}
    >
      <ModalOverlay />
      <ModalContent width={noModal ? "100vw" : ["full", "full", "80vw"]}>
        {confirmModal}
        <HStack width="100%" justify="space-between" >
          <ModalHeader flexGrow={1} width="92%">{caption}</ModalHeader>
          withFullscreenSwitch && <IconButton m="10px" justifySelf="end" variant="ghost" icon={maximized ? <IconArrowsDiagonalMinimize size="20px" /> : <IconArrowsDiagonal2 size="20px" />} aria-label={maximized ? "Shrink" : "Maximize"} onClick={() => setMaximized(!maximized)} />
        </HStack>

        <ModalBody>
          {children(editedValue, setEditedValue)}
        </ModalBody>
        {!noModal && (
          <ModalFooter>
            <Button size={"sm"} mr={2} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              size={"sm"}
              colorScheme="brand"
              onClick={() => {
                
                onOk(editedValue);
              }}
            >
              OK
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
