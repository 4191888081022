import { useState } from "react";
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";


export function InfoModal({ caption, onClose, children }: {
    caption?: string | JSX.Element;
    
    onClose: () => void;
    
    children:  JSX.Element;
}) {
    
    return (<Modal 
        autoFocus={false}
        size="5xl"
        
        isOpen={true} onClose={() => {
            onClose();
    }}>
        <ModalOverlay />
        <ModalContent width="80vw" >
          
            <ModalHeader>{caption}</ModalHeader>
            <ModalBody  maxH="60vh"  overflow="auto">
                <Box>

                {children}
                </Box>
            </ModalBody>
            <ModalFooter>
          
                <Button
                    size={"sm"}
                    colorScheme='brand'
                    onClick={() =>onClose()}
                >
                    Close
                </Button>
                
            </ModalFooter>
        </ModalContent>
    </Modal>);
}
