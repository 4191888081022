

import { useEffect, useMemo, useState } from "react";

import { v4 as uuidv4 } from 'uuid';
import { Link as ReactRouterLink } from 'react-router-dom'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";

import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, AvatarBadge, Badge, Box, Button, Card, Flex, FormControl, FormLabel, HStack, Heading, Image, Input, InputGroup, InputLeftElement, Link, PinInput, PinInputField, Select, Spinner, Stack, Tag, TagLabel, Text, Textarea, Tooltip, VStack, Wrap, WrapItem, useToast } from "@chakra-ui/react";
import { IconAlertTriangleFilled, IconBolt, IconBook2, IconBrandChrome, IconChevronDown, IconChevronUp, IconDownload, IconLayoutGridAdd, IconLogout, IconSend, IconWand } from "@tabler/icons-react";
import { IconSwitchHorizontal } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import IntegrationsPage from "./integrationsPage";
import { DataSourcesSection } from "../components/DataSourcesEdit/dataSourcesSection";
import { AppProject } from "../models/dataModel";
import AppSelect from "../components/AppSelect/appSelect";
import AppSkillsEdit from "../components/AppDetail/appSkillsEdit";
import { ChatContainer, GeniouslyThemeProvider, ChatService } from "geniously-chat-ui"
import { IconSearch } from "@tabler/icons-react";
import { IconAdjustments } from "@tabler/icons-react";
import { AutoUI } from "../components/AutoUI/AutoUI";
import useSWR, { mutate } from 'swr'
import { EditModal } from "../components/ModalDialogs/editModal";
import { IconX } from "@tabler/icons-react";
import Ajv from "ajv"
import { IconZoomCheck } from "@tabler/icons-react";

const AccountBox = ({
    user, tenant, sessionId, selected, status, active, onClick }: {
        user: string,
        tenant: string,
        sessionId: string,
        selected?: boolean,
        active?: boolean,
        status?: string,
        onClick?: () => void
    }) => {


    return (
        <Button variant={selected ? "outline" : "ghost"} colorScheme={active || selected ? "orange" : "gray"} opacity={(active || selected) ? undefined : 0.4} p="5px" height="60px" onClick={(e) => onClick()}>
            {selected &&
                <Flex p="5px" background={active ? "orange.500" : "gray.300"} rounded="full" alignSelf="stretch" height="100%" flex={1} margin="0px 5px 0px 0px">
                </Flex>
            }
            <HStack align="start" width="300px" justify="stretch" >
                <Avatar boxSize="1.25em" bg="black" />
                <Stack width="100%" spacing={0}>

                    <HStack justify="space-between" m="5px 0px">
                        <Text textAlign="start" fontWeight={900}>{user}</Text>
                        <Badge colorScheme="pink" variant="solid" fontSize="xs">{status}</Badge>

                    </HStack>
                    <Tooltip label="tenant_id"><Text textAlign="start" fontSize="2xs">{tenant}</Text></Tooltip>
                    <Tooltip label="session_id"><Text textAlign="start" fontSize="2xs" >{sessionId}</Text></Tooltip>
                </Stack>
            </HStack>
        </Button>)

}

const IntermediateResultBox = ({ actionResult }: { actionResult: any }) => {

    const formatArg = (arg: any) => {
        if (!arg) {
            return "null"
        }
        else if (typeof arg == "object") {
            return "obj"
            return JSON.stringify(Object.keys(arg).reduce((acc, key) => {
                if (!key.startsWith('$')) {
                  acc[key] = arg[key];
                }
                return acc;
              }, {}))
        }
        else {
            return arg.toString()
        }
    }

    function resultSize(result: any) {
        if (result && typeof result == "object") {
            if (Array.isArray(result)){
                return result.length
            }else{

                let keys= Object.keys(result)
                if (keys.length==1){
                    if (result[keys[0]])
                        return resultSize(result[keys[0]])
                    else
                        return 0
                }
                else{
                    return keys.length
                }
            }
        }
        else {
            return 1
        }
    }

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <Box border="1px solid lightgray" p="1px" rounded={5}>
                    <AccordionButton>
                        <Stack align="start">
                            <Flex direction="column" align="start">
                                <Text fontWeight={900}>{actionResult.action_id}</Text>
                                <HStack>
                                    <Text>{"("}</Text>
                                    <Wrap>
                                    {actionResult.args &&
                                        Object.keys(actionResult.args).map(argKey => (<WrapItem> <HStack >
                                            <Text color="gray.600" fontSize="xs">{argKey}: </Text>
                                            <Text fontSize="xs" color="black" fontWeight={900}>{formatArg(actionResult.args[argKey])}</Text>
                                            ,
                                        </HStack></WrapItem>))}
                                    </Wrap>
                                    <Text>{")"}</Text>
                                </HStack>
                            </Flex>
                            <HStack>
                            
                            {actionResult.error && <Text color="red.900">Error</Text>}
                            {!isExpanded && ( actionResult.result ? <Badge fontSize="sm">Show results {resultSize(actionResult.result )}</Badge>
                            : <Badge fontSize="sm">No results</Badge>)}
                            {actionResult.success? <IconCheck color="green"/>:<IconX color="red"/>}
                            sss
                            </HStack>
                        </Stack>
                        {/* <Text>{result.type} </Text> */}
                    </AccordionButton>
                    <AccordionPanel>
                        {actionResult && (
                            typeof (actionResult.result) === "object" ? <AutoUI value={actionResult.result} layout="vertical" /> : (
                                <Text>{actionResult.result}</Text>
                            ))}
                        {actionResult.error && <Box>
                            <Text color="red.900">{actionResult.error}</Text>
                        </Box>}
                    </AccordionPanel>
                </Box>
            )}
        </AccordionItem>

    )
}


const ReviewMessage = ({msg, onSend}:{
    msg:any
    onSend:(data:any)=>any
})=>{
    const [currentData, setCurrentData] = useState<any>(msg.data)
    return <Stack   spacing="5px" m="3px" border="1px solid lightgray" p="4px" rounded="5px" >
        <HStack>
            <IconZoomCheck/>
            <Text fontSize="xs">Review</Text>
        </HStack>
            <Accordion allowToggle defaultIndex={msg.reviewed?undefined:1}>
                <AccordionItem>
                        <AccordionButton p="0px 5px">
                            <AccordionIcon />
                            <Text fontSize="xs">Context</Text>
                        </AccordionButton>
                    <AccordionPanel>
                        <Stack>
                            
                            <Text fontSize="xs">{msg.message}</Text>
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                        <AccordionButton p="2px 5px">
                            <AccordionIcon />
                            <Text fontSize="xs">Data </Text>
                        </AccordionButton>
                    <AccordionPanel>
                        <Stack>
                            
                            
                            {typeof(msg.data)==="string"? 
                            <AutoUI readOnly={msg.reviewed} value={{text:currentData}} layout="vertical" onValueChange={(val)=>setCurrentData(val.text)}/>
                            :(
                            <AutoUI readOnly={msg.reviewed}  value={currentData} layout="vertical"  onValueChange={(val)=>setCurrentData(val)}/>
                            )}
                            {!msg.reviewed && <Button 
                                size="xs" 
                                onClick={()=>{
                                    onSend(currentData)
                                }} 
                                colorScheme="facebook"
                                alignSelf="end"
                                leftIcon={<IconSend/>}
                            >Apply and send</Button>}
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>
}

const ActionResults = ({ results }: { results: any[] }) => {
    return <Accordion allowToggle>
        {results?.map((result) => (
            <IntermediateResultBox actionResult={result} />
        ))}
    </Accordion>
}

const MessageComposeBox=({val,setVal})=>{
    
    return <Stack width="100%" spacing="5px">
        <Textarea placeholder='Message text' value={val.message} onChange={e=>setVal({...val,message:e.target.value})}/>
        <Stack>
            {val?.widgets?.map((widget, i)=>(
                <Card p="5px" shadow="lg" border="1px solid gray">
                    <AutoUI value={widget} layout="vertical" allowAddNew={true} onValueChange={(newVal)=>{
                        if (newVal.widgets?.length){
                            setVal({...val, widgets:[...newVal.widgets.slice(0,i), newVal, ...newVal.widgets.slice(i+1)]})
                        }
                        else{
                            setVal({...val, widgets:[newVal]})
                        }
                    }} 
                    excludeFields={["id"]}
                    
                    schema={{
                        type:"object",
                        properties:{
                            "widget_type":{type:"string", enum:["data"]},
                            "title":{type:"string"},
                            "text":{type:"string"},
                            "data":{type:"object", ...(widget.data_schema||{}) ,additional_properties:true},
                        },
                        required:["widget_type"]
                    }}
                    />
                </Card>
            ))}
        </Stack>
        <Button onClick={()=>setVal({...val, widgets:[...(val.widgets||[]),{
            id:uuidv4(),
            widget_type:"data",
            data:{}
            
        }]})}  size="xs" colorScheme="gray" leftIcon={<IconLayoutGridAdd/>} >
            Add widget
        </Button>
        
    </Stack>
}

export default function HumanLoopPage({ }) {

    const { currentUser, currentAppProject, setCurrentAppProject } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedSessionId = searchParams.get("sessionId")
    const selectSession = (sessionId: string) => {
        setSearchParams({ ...(Object.fromEntries(searchParams.entries())), sessionId })
    }
    const navigate = useNavigate()
    const [appValue, _setAppValue] = useState<AppProject>()
    const setAppValue = (app: AppProject) => {
        _setAppValue(app)
    }
    useEffect(() => {
        if (currentAppProject) {
            setAppValue(currentAppProject)
        }
    }, [currentAppProject])


    const { data: allSessions, isLoading: sessionsLoading } = useSWR(`supportSessions`, () => getApi().getSupportSessions(),{
        
    })
    const selectedSession = useMemo(() => allSessions?.find((s) => s.id == selectedSessionId), [allSessions, selectedSessionId])

    const { data: sessionMessages, isLoading: messagesLoading } = useSWR(`${selectedSessionId}/messages`, () => selectedSessionId ? getApi().getSupportSessionsMessages(selectedSessionId) : undefined, {revalidateOnFocus:false})

    const { data: tools, isLoading: toolsLoading } = useSWR(`${selectedSessionId}/tools`, () => selectedSessionId ? getApi().getSupportSessionsTools(selectedSessionId) : undefined,{
        revalidateOnMount: false,
        revalidateIfStale: false,
        revalidateOnFocus:false
    })
   

    const toast = useToast()
    const [isSearching, setIsSearching] = useState<boolean>()
    const [isExecuting, setIsExecuting] = useState<boolean>()
    const [filters, _setFilters] = useState<any>({})
    const setFilters = (filters: any) => {
        _setFilters(filters)
    }
    const [modal,setModal]=useState<any>()
    const [selectedDs, _setSelectedDs] = useState<string>()
    const setSelectedDs = (ds: string) => {
        if (ds !== selectedDs) {
            _setSelectedDs(ds)
            setFilters({})
        }
    }
    const validateFilters = useMemo(()=>{
        const filter_schema = tools?.datasources?.find(ds => ds.name === selectedDs)?.filters
        if (filter_schema){
            const ajv = new Ajv({strict: false});
            try{

                let res = ajv.compile(filter_schema)
                return res
            }
            catch(e){
                console.error(e)
            }
        }
    },[tools, selectedDs])

    useEffect(()=>{
        if (validateFilters){

            const validationResult = validateFilters(filters)
            setFilterError(!validationResult)
        }
        else{
            setFilterError(false)
        }
    },[filters, validateFilters])

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>()
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [selectedCommand, _setSelectedCommand] = useState<string>()


    //const [intermediateResults, setIntermediateResults] = useState<any[]>()
    const addIntermediateResult = (result: any) => {

        setMessages((prev)=>[...(prev || []), result])
    }

    const [commandArgs, setCommandArgs] = useState<any>({})
    const setSelectedCommand = (cmd: string) => {
        if (cmd !== selectedCommand) {
            _setSelectedCommand(cmd)
            console.log(tools.commands[cmd])
            setCommandArgs({})
        }
    }

    function executeSearch() {
        if (selectedDs) {
            setIsSearching(true)
            let ds_id = tools.datasources.find(ds => ds.name === selectedDs)?.datasource_id;
            getApi().supportSessionsDatasourceSearch(selectedSessionId, ds_id, searchQuery, filters).then((res) => {
                addIntermediateResult(res)

                setSearchQuery("")
            }).finally(() => setIsSearching(false))
        }
    }

    function executeCommand() {
        if (selectedCommand) {
            setIsExecuting(true)

            getApi().supportSessionsExecuteAction(selectedSessionId, selectedCommand, commandArgs).then((res) => {
                if (res){
                    addIntermediateResult(res)
                }

            }).finally(() => setIsExecuting(false))
        }
    }

    const [messages, setMessages] = useState<any[]>([])
    useEffect(() => {
         setMessages(sessionMessages || [])
    }, [sessionMessages])
    const [chatService, setChatService] = useState<ChatService>()

    const [lastSelectedSessionId, setLastSelectedSessionId] = useState<string>()
    useEffect(() => {
        if (!chatService && selectedSessionId && currentUser) {
            setLastSelectedSessionId(selectedSessionId)
            initChatService()

        }
    }, [chatService, currentUser, selectedSessionId])


    useEffect(() => {

        if (chatService && chatService.sessionId !== selectedSessionId) {
            chatService.setSessionId(selectedSessionId)
            setLastSelectedSessionId(selectedSessionId)
        }

    }, [selectedSessionId])

    function preprocessMessages(arr: any[]) {
        if (!arr) return []
        else
            return arr.reduce((acc, curr) => {
                if (curr.action_id) {
                    const lastGroup = acc[acc.length - 1];
                    if (Array.isArray(lastGroup) && lastGroup[0].action_id === curr.action_id) {
                        lastGroup.push(curr);
                    } else {
                        acc.push([curr]);
                    }
                } else {
                    acc.push(curr);
                }
                return acc;
            }, [])
    };


    function initChatService() {

        let finalWsEndpointUrl = new URL((process.env.REACT_APP_API_URL.replace("http", "ws") || "http://localhost:4080") + `/support/sessions/chat`)


        let authorizeFunc = () => currentUser.getAccessToken().then(token => ({ "Authorization": `Bearer ${token}` }))

        const service = ChatService.getInstance({
            wsEndpoint: finalWsEndpointUrl.toString(),
            sessionId: selectedSessionId,
            authorizeFunc: authorizeFunc as any,
            disconnectTimeoutSec:0,
            onHistoryRetrieved: (historical_messages) => {
                // if (historical_messages?.length && historical_messages[historical_messages.length - 1].timestamp) {
                //     setMessages(messages)
                // }
            },
            onStatusUpdate: (message) => {
                setStatusMsg(message)
            },
            onNewMessageReceived: (message) => {

                setStatusMsg(undefined)
                if (message.message_status == "streaming") {
                    // setStreamingMessages(prev=>[...(prev??[]), message])
                    // setTemporalStatusMsg(undefined)
                    // setIsTyping(true)
                }
                else {
                    // if (pendingRequestMsgRef.current){

                    //     setMessages((prev)=>{
                    //         return [...(prev??[]),pendingRequestMsgRef.current as ChatMessage, message]
                    //     })
                    //     setTemporalStatusMsg(undefined)
                    // }
                    // else{
                    let exists = messages?.find((m) => m.id == message.id)
                    if (!exists) {
                        setMessages((prev) => [...(prev ?? []), message])
                    }
                    else {
                        setMessages((prev) => [...(prev ?? []).map((m) => m.id == message.id ? message : m)])
                    }
                    // }

                    // setPendingRequestMsg(undefined)
                    // setStreamingMessages([])
                    // setIsTyping(false)
                    //setIsTyping(false)
                }
            },
            onStream: (streamText) => {
                // setLatestNotificationState(undefined)
                // setStreamingMessages((currentStreamingMessages:ChatMessage[])=>{

                //     let newStreamingMessage:ChatMessage = currentStreamingMessages?.length  ? {...currentStreamingMessages[currentStreamingMessages.length-1]}:{
                //         message:"",
                //         type:"chat-response",
                //         message_status:"streaming"
                //     }
                //     newStreamingMessage.message += streamText
                //     if (currentStreamingMessages?.length){
                //         currentStreamingMessages[currentStreamingMessages.length-1] = newStreamingMessage
                //     }

                //     return [...currentStreamingMessages]
                // })

                // setTemporalStatusMsg(undefined)
            },
            //onStatusUpdate:(message)=>{
            // setLatestNotificationState(undefined)
            // setStreamingMessages([])
            // if (message?.status_type=="received" || message?.status_type=="typing"){
            //     setIsTyping(true)
            //     return
            // }
            // if (message?.status_type=="waiting"){
            //     setIsTyping(false)
            //     return
            // }
            // else{
            //     if (message?.status_type=="error" && pendingRequestMsgRef.current){
            //         setPendingRequestMsg({...pendingRequestMsgRef.current, message_status:"error"} )
            //     }
            //     setIsTyping(false)
            // }
            // if (!message){
            //     // no message means we clear the temporal status message
            //     setTemporalStatusMsg(undefined)
            // }
            // else if(message.is_temporary){
            //     setTemporalStatusMsg(message)
            // }
            // else{
            //     setTemporalStatusMsg(undefined)
            //     setMessages((prev)=>[...prev, message])
            // }
            //},
            onDisconnect: () => {
                // setIsTyping(false)
                // if (pendingRequestMsgRef.current){
                //     let pendingAsError = {...pendingRequestMsgRef.current as ChatMessage, message_status:"error"}
                //     setMessages((prev)=>{
                //         let res:any = [...(prev??[]),pendingAsError]

                //         setPendingRequestMsg(undefined)
                //         return res
                //     })

                // }
            }
        },
            true //openConnection
        )
        if (service !== chatService)
            setChatService(service)
    }

    const [sendingMessage, setSendingMessage] = useState<any>()
    const [statusMsg, setStatusMsg] = useState<any>()
    const [filterError, setFilterError] = useState<any>()

    function onSend(msg) {
        if (chatService) {
            (msg as any).author = "support";
            if (!msg.metadata?.widget_id){
                (msg as any).type = "chat-response";
                
            }else{
                msg.hidden = true;
            }
            (msg as any).message_status = "sent";
            
            setSendingMessage(msg)
            chatService?.sendChatMessage(msg as any, (message, ack) => {
                if (sendingMessage?.message != message.message)
                    setSendingMessage(undefined)
                if (ack) {
                    
                    setMessages((prev) => {
                        console.log(message.message)
                        if (msg.message == message.message) {                            
                            return [...(prev || []),{...msg,message_status:"received"}]
                        }
                        else {
                            return prev
                        }
                    })
                }
                else {
                    if (msg.message == message.message) {
                        setMessages((prev) => [...(prev || []), { ...msg, message_status: "error" }])
                    }
                }
            })
            
        }
        else{
            initChatService()
            setTimeout(()=>{
                onSend(msg)
            },1000)
        }
    }

    function composeReply(editMsg:any=null, onOk:(message:any)=>any=undefined, onCancel:()=>any=undefined) {
        let msg = editMsg?{...editMsg}:{ message: "" }
        setModal(<EditModal caption="Compose reply" value={msg} 
        onCancel={()=>{
            setModal(undefined)
            onCancel && onCancel()
        }}
        onOk={(val) => {
            if (onOk){
                onOk(val)
            }
            else{
                onSend(val)
            }
            setModal(undefined)
        }}>
            {(value,onChange)=>(<MessageComposeBox val={value} setVal={onChange}/>)}
        </EditModal>)
    }

    function handleLookup(query: string, lookup_datasource_id: any) {
      
        return getApi().handleLookup(selectedSession.app_id,lookup_datasource_id,query).then((res)=>{
            return res//.map((item)=>({value:item.document_id, label:item.title ||item.text||item.document_id, secondaryLabel:item.text}))
        })
    }

    return currentUser?.tenant_id!="1"?(
        <Box>{currentUser?.email} Unauthorized</Box>
    ):(
        <Stack justify="center" align="center" direction="column" className="general-page" height="100vh" >
            {modal}
            <Flex direction="row" justify="space-between" width="100%" maxH="25px">
                <Box onClick={() => navigate("/")} p="10px">
                    <Logo className="logo-icon" size={"large"} withText />
                </Box>
                <Button margin="5px" as={ReactRouterLink} to="/login" leftIcon={<IconLogout />} variant="ghost">Logout</Button>
            </Flex>

            <Stack align="center" flexGrow={1} m="20px">

                <Card p="20px" shadow="2xl" width={["95vw"]} height="100%" borderWidth={1} borderColor="gray.400" maxH="90vh">
                    <HStack height="100%" justify="stretch">
                        <VStack border="1px solid gray" rounded={5} height="100%" p="5px" minWidth="350px" overflow="auto">
                            {sessionsLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {allSessions?.map((s) => (
                                        <AccountBox user={s.userName} tenant={s.visitor_id} sessionId={s.id} selected={selectedSessionId === s.id} active={!s.closed}
                                            onClick={() => selectSession(s.id)}
                                            status={s.status} />
                                    ))}
                                </>
                            )}

                            {/* // <AccountBox user="dominic@tvbrookers.com" tenant="985b0a3f-294a-4897-9ebc-527cfd6ee338" active unreadCount={1}/>
                            // <AccountBox user="roi@easypie.ai" tenant="b5dc295e-7a43-4738-b88a-5be910fc475d" />
                            // <AccountBox user="juraj@easypie.ai" tenant="df37e9c4-b90d-42fe-b974-e77ef7cd2b40" unreadCount={3}/> */}
                        </VStack>
                        {messagesLoading ? (
                            <Stack width="100%" height="100%" justify="center" align="center">

                                <Spinner alignSelf="center" justifySelf="center" size="lg" />
                            </Stack>
                        ) : (
                            <Stack height="100%" border="1px solid gray" rounded={5} flex={1} overflow="auto">
                                <GeniouslyThemeProvider>
                                    <ChatContainer
                                        messages={[...preprocessMessages(messages), sendingMessage || <></>, statusMsg || <></>]
                                            .map(m => Array.isArray(m) ? <ActionResults results={m} /> : m)
                                        }
                                        canEdit={(message)=>chatService && (message as any).type == "chat-response" /*&& (message as any).hidden*/}
                                        customMessageComponentRenderer={(msg,i)=>(
                                            msg?.type=="review-request" ? (
                                                <ReviewMessage msg={msg} key={i} 
                                                onSend={(data)=>{

                                                    chatService.sendChatMessage(
                                                        {...msg, data: data, reviewed:true}, (message, ack) => {
                                                            if (ack){
                                                                const _messages = [...messages]
                                                                _messages[i] = message
                                                                setMessages(_messages)
                                                                toast({ title: "Success", description: "Sent", status: "success", duration: 2000, isClosable: true })
                                                            }
                                                            else{
                                                                toast({ title: "Failed", description: "Error during sending", status: "error", duration: 2000, isClosable: true })
                                                            }
                                                        }
                                                    )
                                                }}
                                                />
                                            ):undefined
                                        )}      
                                        
                                        getChatMessagesActions={(message) => {
                                            const hide=[
                                                {
                                                    label: "🚫 Hide",
                                                    primary: true,
                                                    onClick: () => {
                                                        return new Promise((resolve, reject) => {
                                                            chatService.sendChatMessage({
                                                                type: "edit",
                                                                id: (message as any).id,
                                                                edit_message_id: (message as any).id,
                                                                edit: { hidden: true, metadata: { hidden_by: currentUser.name } }
                                                            } as any, (message, ack) => {
                                                                chatService.fetchHistory(true)
                                                                if (ack) {
                                                                    toast({ title: "Success", description: "Hidden", status: "success", duration: 2000, isClosable: true })
                                                                    resolve(message)
                                                                }
                                                                else {
                                                                    toast({ title: "Failed", description: "Error during hiding", status: "error", duration: 2000, isClosable: true })
                                                                    reject("Unacknowledged")
                                                                }
                                                            })
                                                        })
                                                    }
                                                }
                                            ].filter((a)=>!(message as any)?.hidden && ((message as any)?.type==="chat-response"))

                                            if (chatService && (message as any).type == "chat-response" /*&& (message as any).hidden */) {
                                                return [
                                                    ...hide,
                                                    {
                                                        label: "👍 Verify&Send",
                                                        primary: true,
                                                        onClick: () => {
                                                            return new Promise((resolve, reject) => {
                                                                chatService.sendChatMessage({
                                                                    type: "edit",
                                                                    id: (message as any).id,
                                                                    edit_message_id: (message as any).id,
                                                                    author:"support",
                                                                    edit: { hidden: false, metadata: { verified_by: currentUser.name } }
                                                                } as any, (message, ack) => {
                                                                    chatService.fetchHistory(true)
                                                                    if (ack) {
                                                                        toast({ title: "Success", description: "Verified", status: "success", duration: 2000, isClosable: true })
                                                                        resolve(message)
                                                                    }
                                                                    else {
                                                                        toast({ title: "Failed", description: "Error during verification", status: "error", duration: 2000, isClosable: true })
                                                                        reject("Unacknowledged")
                                                                    }
                                                                })
                                                            })
                                                        }
                                                    },
                                                    {
                                                        label: "✏️ Edit",
                                                        primary: true,
                                                        onClick: () => {
                                                            return new Promise((resolve, reject) => {
                                                                composeReply(message, (message)=>resolve(message), ()=>resolve(undefined))
                                                            }).then((message)=>{
                                                                if (!message) {
                                                                    return message
                                                                }
                                                                else{
                                                                    setMessages((prev) => [...(prev || []).map((m) => m.id == (message as any).id ? message : m)])
                                                                    chatService.sendChatMessage({
                                                                        type: "edit",
                                                                        id: (message as any).id,
                                                                        edit_message_id: (message as any).id,
                                                                        author:"support",
                                                                        edit: { 
                                                                            message:(message as any).message, 
                                                                            widgets:(message as any).widgets, 
                                                                            hidden: false, 
                                                                            metadata: { verified_by: currentUser.name } 
                                                                        }
                                                                    } as any, (message, ack) => {
                                                                        chatService.fetchHistory(true)
                                                                        if (ack) {
                                                                            toast({ title: "Success", description: "Edited", status: "success", duration: 2000, isClosable: true })
                                                                            return message
                                                                        }
                                                                        else {
                                                                            toast({ title: "Failed", description: "Error during verification", status: "error", duration: 2000, isClosable: true })
                                                                            throw Error("Unacknowledged")
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    },
                                                ]
                                            }
                                            else{
                                                return hide
                                            }
                                        }}

                                        onSend={onSend}
                                        showInput={false}
                                        onResetConversation={undefined}
                                    />
                                </GeniouslyThemeProvider>
                                {/* {chatService?.isActiveConnection && chatService?.sessionId == selectedSessionId ? (

                                    <Text textAlign="center" m="-10px 0px 0px" fontSize="sm" fontWeight={900}>(online) ⬆️ Watchout!!! ... sending message you will reply as AI to customer ! ⬆️</Text>
                                ) : (
                                    <Flex>
                                        <Text>{(chatService)?.sessionId == selectedSessionId ? "Disconnected" : "Session id mismatch"}</Text>
                                    </Flex>
                                )} */}
                                
                                {!tools ? ( toolsLoading?<Spinner /> : <Flex><Text>Please refresh</Text>
                                <Button size="xs" onClick={()=>{
                                    if (!chatService?.isActiveConnection){
                                        chatService?.connect()
                                    }
                                    else{
                                        mutate(`${selectedSessionId}/tools`)
                                    }
                                }}>Refresh</Button>
                                </Flex>) : (
                                    <Box m="5px" p="2px" border="1px solid lightgray" rounded={5}  >
                                        <Text fontSize="xs" fontWeight={900}>Available tools</Text>
                                        <HStack p="5px">
                                            <Flex flex={1}>
                                                <InputGroup>
                                                <InputLeftElement pointerEvents='none'>
                                                <IconSearch color='lightgray' />
                                                </InputLeftElement>
                                                    <Input type="text" placeholder="Search phrase"
                                                        onKeyDown={(e) => {
                                                            //once enter is pressed
                                                            if (e.key === 'Enter') {
                                                                executeSearch()
                                                            }
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Flex>
                                            <Button size="sm" leftIcon={<IconSearch />} isDisabled={!selectedDs}
                                                isLoading={isSearching}
                                                onClick={() => executeSearch()}
                                            >Search</Button>
                                            <Button size="sm" 
                                                leftIcon={filterError? <IconAlertTriangleFilled/>:<IconAdjustments />} 
                                                color={filterError?"red":undefined}
                                                rightIcon={filtersExpanded ? <IconChevronUp /> : <IconChevronDown />} 
                                                isDisabled={!(selectedDs && tools?.datasources.find(ds => ds.name === selectedDs)?.filters)} onClick={() => setFiltersExpanded(!filtersExpanded)}
                                            >
                                                Filters
                                            </Button>
                                        </HStack>
                                        {filtersExpanded &&tools && <Stack m="2px" p="3px" border="1px solid lightgray" rounded={2}>
                                            <AutoUI
                                                value={filters}
                                                handle_lookup={(val, fieldSchema)=>handleLookup(val, fieldSchema.lookup_datasource)}
                                                onValueChange={(v) =>{

                                                     setFilters(v)

                                                    }}
                                                schema={tools?.datasources.find(ds => ds.name === selectedDs)?.filters}
                                            />
                                        </Stack>}
                                        <HStack p="5px" m="-5px 0px 0px">
                                            <Wrap>
                                            {tools?.datasources?.map((ds) => (
                                                <WrapItem>
                                                    <Tag
                                                        size="sm"
                                                        onClick={() => setSelectedDs(ds.name)}
                                                        borderRadius='full'
                                                        variant={ds.name !== selectedDs ? 'outline' : "solid"}
                                                        colorScheme='pink'
                                                        cursor="pointer"
                                                    >
                                                        <TagLabel>{ds.name}</TagLabel>
                                                    </Tag>
                                                </WrapItem>

                                            ))}
                                            </Wrap>
                                        </HStack>
                                        <HStack p="5px">
                                            
                                            <Text fontWeight={900} fontSize="xs">Select command to execute</Text>
                                            <Select  size="sm" placeholder="[ Select action ]" width="400px" onChange={e => {
                                                if (e.target.selectedIndex > 0) {
                                                    setSelectedCommand(e.target.selectedOptions[0].label)
                                                }
                                                else {
                                                    setSelectedCommand(undefined)
                                                }
                                            }}>

                                                {tools?.commands && Object.keys(tools.commands).map(c => (<option value={c}>{c}</option>))}
                                            </Select>
                                            
                                        </HStack>
                                        {selectedCommand && (
                                            <Box p="3px" m="2px" border="1px solid lightgray" rounded={3}>
                                                <Accordion allowToggle defaultIndex={0}>
                                                    <AccordionItem>
                                                    <AccordionButton>
                                                        <Stack justify="start" align="start" spacing={0}>
                                                            <HStack>
                                                                <AccordionIcon />
                                                                <Text  fontSize="sm"><b>{selectedCommand}</b>  arguments</Text>
                                                            </HStack>
                                                            <Text fontSize="xs">
                                                            {commandArgs && JSON.stringify(commandArgs)}
                                                            </Text>
                                                        </Stack>
                                                    </AccordionButton>
                                                    <AccordionPanel>
                                                        <AutoUI
                                                            value={commandArgs}
                                                            schema={tools.commands[selectedCommand]}
                                                            handle_lookup={(val, fieldSchema)=>handleLookup(val, fieldSchema.lookup_datasource)}
                                                            onValueChange={(v) => {
                                                                setCommandArgs(v)
                                                            }}
                                                        />
                                                    </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </Box>
                                        )}
                                          <Button size="sm" colorScheme="pink" leftIcon={<IconBolt />}
                                                isDisabled={!selectedCommand}
                                                isLoading={isExecuting}

                                                onClick={() => executeCommand()}>Execute command</Button>

                                    </Box>
                                )}
                                <Stack>
                                    <Button size="xs" m="2px 15px" leftIcon={<IconSend/>} onClick={()=>composeReply()}>Compose new message</Button>
                                </Stack>
                            </Stack>)}

                    </HStack>
                </Card>


            </Stack>
        </Stack>
    )
}