

import { useCallback, useEffect, useMemo, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Editable, EditableInput, EditablePreview, Tooltip } from "@chakra-ui/react";

import { useAppContext } from "../appContext";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { TopicInsightsBox } from "../components/Insights/topicInsights";
import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, WidgetContainer } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconArrowsDiagonal2, IconBoxAlignBottomLeftFilled, IconChevronDown, IconChevronRight, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconPlayerPauseFilled, IconPlayerPlay, IconPlus, IconRefresh, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { Reorder } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useSWR, { mutate } from "swr";
import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";
import { IconFilter } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import Navigation from "../components/Navigation/navigation";
import GenericList from "../components/GenericList/genericList";
import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";
import { ListOpDialog } from "../components/Lists/listOpDialog";
import { IconCircleDashed } from "@tabler/icons-react";
import { IconRotateClockwise2 } from "@tabler/icons-react";
import ColumnsSelector from "../components/listUI/columnsSelector";

interface DataListInfo {
    id: string
    name: string,
    data_page: { [key: string]: any }[],
    page: number
    operations: ListOp[]
    filter: { [key: string]: any }
    total_count: number
    version: number
    archived: boolean
    columns: { name: string, default_hidden: boolean, type: string, [key: string]: any }[]
    column_schema: JSONSchema


}

interface DataListColumn {
    // Define the structure of DataListColumn based on your requirements.
}

interface ListPreview {
    id: string;
    widget_type: "list_preview";
    preview_data: Array<{ [key: string]: any }>;
    filter?: { [key: string]: any };
    columns?: Array<DataListColumn>;
    columns_schema?: { [key: string]: any };
    total_items: number;
    description?: string;
}

interface CommandSectionDescription {
    [funcName: string]: {
        name: string,
        interactive: boolean,
        supports_prompting: boolean,
        supports_preview: boolean,
        schema: JSONSchema
    }
}

const ReRunOptions = ({ optionsValue, setOptionsValue }: {
    optionsValue: { newRecords: boolean, emptyAndFailed: boolean, allRecords: boolean },
    setOptionsValue: (value: any) => void
}) => {

    return <Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["newRecords"]} onChange={(e) => {
                setOptionsValue({ emptyAndFailed: optionsValue.emptyAndFailed, "newRecords": e.target.checked })
            }}>New records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">Records that have been added later, or record that have been filtered out by the view</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["emptyAndFailed"]} onChange={(e) => {
                setOptionsValue({ newRecords: optionsValue.newRecords, "emptyAndFailed": e.target.checked })
            }}>Empty and failed</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">This includes all records that don't have value (relevant for column operations)</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["allRecords"]} onChange={(e) => {
                setOptionsValue({ "allRecords": e.target.checked })
            }}>All records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">All current values (overwrite existing)</Text>
        </Stack>
    </Stack>
}

const RunOptions = ({ options = [10, 50, 100, 200, 500], stopAfter, onStopAfterChange }: {
    options?: number[],
    stopAfter: number | null,
    onStopAfterChange: (value: number | null) => void
}) => {
    function optionToString(option: number | null) {
        return option ? `stop after ${option} rows` : "Process to the end"
    }
    return <Stack>

        <Menu>
            <MenuButton as={Button} rightIcon={<IconChevronDown />} size="xs" variant="outline" colorScheme="red" >{optionToString(stopAfter)}</MenuButton>
            <MenuList>
                {[...options, null].map((opt, i) => (
                    <MenuItem key={i} onClick={() => {
                        onStopAfterChange(opt)
                    }}>{optionToString(opt)}</MenuItem>
                ))}

            </MenuList>
        </Menu>
    </Stack>
}

const ListOpsForm = ({ listOps, currentOp, executorState, scheduledOps, onOpAction, onExecutorAction }: {
    listOps: ListOp[]
    currentOp?: ListOp,
    executorState: "running" | "ready" | "paused"
    scheduledOps: ListOp[]
    onOpAction?: (op: ListOp, action: "pause" | "resume" | "stop" | "retry" | "rerun" | "cancel", options?: any) => Promise<any>
    onExecutorAction?: (action: "stop" | "resume") => Promise<any>
}) => {

    function getStateIcon(state: "init" | "running" | "paused" | "stopped" | "done" | "error") {
        if (state == "init") {
            return <IconCircleDashed size="15px" />
        }

        else if (state == "paused") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "stopped") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "done") {
            return <Stack color="#50C878"><IconCircleCheckFilled size="15px" /></Stack>
        }
        else if (state == "error") {
            return <Stack color="#FF5733"><IconExclamationCircle color="crimson" size="15px" /></Stack>

        }
        else if (state == "running") {
            return <Stack ><Spinner size="xs" /></Stack>

        }
        else {
            return <Text>state</Text>
        }
    }
    const [modal, setModal] = useState<any>()
    const [waiting, setWaiting] = useState<boolean>()



    return (<Stack >
        {modal}
        {waiting && <Spinner />}
        <Stack spacing={1} opacity={waiting ? 0.5 : undefined} pointerEvents={waiting ? "none" : undefined} maxH="80vh" overflow="auto">

            <Accordion allowToggle index={undefined} >
                {listOps?.map((op, i) => (
                    <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                        {({ isExpanded }) => (
                            <>
                                <HStack>
                                    <AccordionButton p="2px"  >
                                    <AccordionIcon />
                                        <Stack flexGrow={1} align="start">
                                            <Text >{op.name} {op.state}</Text>

                                        </Stack>

                                </AccordionButton>
                                    <HStack m="0px 8px">
                                        {/* <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconRefresh size="15px" />} onClick={() => {

                                        }} /> */}
                                        {op.state_message && <Tooltip label={op.state_message} >
                                            <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" >{op.state_message}</Text>
                                        </Tooltip>}
                                        {getStateIcon(op.state)}
                                        {(op.state == "paused" || op.state == "stopped") && <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                                            setModal(
                                                <EditModal size="sm" withFullscreenSwitch={false} caption="Continue operation" value={op.category == "import" ? 500 : null} onOk={(val: any) => {


                                                    onOpAction(op, "rerun", { overwrite: null, pause_after_steps: val }).then(() => {
                                                        setModal(undefined)
                                                    })


                                                }} onCancel={() => {
                                                    setModal(undefined)
                                                }}>
                                                    {(val, setVal) => (<Stack>
                                                        <Text>Do you want to run the op the end of the list?</Text>
                                                        <RunOptions stopAfter={val} onStopAfterChange={setVal} />
                                                    </Stack>)}
                                                </EditModal>
                                            )

                                        }} >Continue</Button>}
                                    </HStack>
                                </HStack>
                                <AccordionPanel p="0px">
                                    <Stack p="8px">
                                        <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                        {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                            <AutoUI value={op.kwargs} />
                                        )}
                                        {op.stages && (
                                            op.stages.map((stage, i) => (
                                                <Accordion allowToggle index={undefined} >
                                                    <AccordionItem border="1px solid lightgray" borderRadius={4} mt="4px" >
                                                        <AccordionButton p="2px"  >
                                                            <Text fontSize="sm">- {stage.name}</Text>
                                                        </AccordionButton>
                                                        <AccordionPanel p="0px">
                                                            <AutoUI value={stage.args} schema={stage.args_schema} />
                                                        </AccordionPanel>

                                                    </AccordionItem>
                                                </Accordion>

                                            ))
                                        )}
                                        <HStack>
                                            <Button alignSelf="end" size="xs"
                                            onClick={() => {
                                                onOpAction(op, "retry")

                                            }}
                                                leftIcon={<IconRefresh size="15px" />} >Edit / Retry</Button>

                                            {/* {<Button alignSelf="end" size="xs"
                                                onClick={() => {
                                                    if (op.category == "add_column") {


                                                        setModal(
                                                            <EditModal caption="Re-run operation" value={{}} onOk={(val: any) => {
                                                                if ((val && Object.keys(val).length)) {
                                                                    let overwrite = null;

                                                                    if (val.emptyAndFailed) {
                                                                        overwrite = "empty_and_failed"
                                                                    }
                                                                    else if (val.allRecords) {
                                                                        overwrite = "all"
                                                                    }
                                                                    onOpAction(op, "rerun", { overwrite: overwrite }).then(() => {
                                                                        setModal(undefined)
                                                                    })

                                                                }
                                                            }} onCancel={() => {
                                                                setModal(undefined)
                                                            }}>
                                                                {(val, setVal) => (<Stack>
                                                                    <Text>Are you sure you want to re-run the operation?</Text>
                                                                    <ReRunOptions optionsValue={val as any} setOptionsValue={setVal} />
                                                                </Stack>)}
                                                            </EditModal>
                                                        )
                                                    }
                                                    else{
                                                        onOpAction(op, "rerun", )
                                                    }

                                                }}
                                                leftIcon={<IconRotateClockwise2 size="15px" />} >Re-run</Button>} */}




                                        </HStack>

                                    </Stack>
                                </AccordionPanel>
                            </>)}
                    </AccordionItem>
                ))}
            </Accordion>


            {scheduledOps?.length && <Stack>
                <HStack justify="space-between">
                    <Text fontWeight={600} color="gray">Pending</Text>

                </HStack>
                <Accordion allowToggle index={undefined} >
                    {scheduledOps?.map((op, i) => (
                        <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                            {({ isExpanded }) => (
                                <>
                                    <HStack>
                                        <AccordionButton p="2px"  >
                                            <AccordionIcon />
                                            <Stack flexGrow={1} align="start">
                                                <Text >{op.name} {op.state}</Text>

                                            </Stack>

                                        </AccordionButton>
                                        <HStack m="0px 8px">
                                            {getStateIcon(op.state)}
                                            <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconX size="15px" />} onClick={() => {
                                                setWaiting(true)
                                                onOpAction(op, "cancel").finally(() => {
                                                    setWaiting(false)
                                                })
                                            }} />
                                        </HStack>
                                    </HStack>
                                    <AccordionPanel p="0px">
                                        <Stack spacing={1} p="8px">
                                            <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                            {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                                <AutoUI value={op.kwargs} />
                                            )}

                                        </Stack>

                                    </AccordionPanel>
                                </>)}
                        </AccordionItem>
                    ))}
                </Accordion>

            </Stack>}

            <HStack justify="space-between" m="10px 0px" p="4px 8px" border="1px solid gray" borderRadius="8px" shadow="md" backgroundColor="gray.50">
                {currentOp && <Text fontWeight={900}>Current: {currentOp.name}</Text>}
                <HStack justifySelf="end">
                    {getStateIcon(executorState as any)}  <Text fontSize="xs">Execution {executorState}</Text>
                    <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                        onExecutorAction(executorState == "running" ? "stop" : "resume")
                    }}>{executorState == "running" ? "Stop" : "Resume"}</Button>
                </HStack>
            </HStack>
        </Stack>

    </Stack>
    )
}


const CampaignForm = ({ campaign, schema, chatService, onClose }: {
    campaign: {
        campaign_name: string,
        lead_linkedin_url: string,
        account: string,
        campaign_start: string,
        messaging_steps: {
            step_name?: string
            source_column?: string
            delay_days?: number
        }[]
    },
    schema: JSONSchema,
    chatService?: ChatService
    onClose: () => void
}
) => {
    const [campaignData, setCampaignData] = useState(campaign)

    return !campaignData ? (<></>) : (
        <Stack spacing={4}>

            <Stack spacing={1}>
                <AutoUI value={{
                    campaign_name: campaignData.campaign_name,
                    account: campaignData.account,
                    lead_linkedin_url: campaignData.lead_linkedin_url,
                    campaign_start: campaignData.campaign_start
                }}
                    onValueChange={(val) => {
                        setCampaignData({ ...campaignData, ...val })
                    }}
                    schema={{
                        type: "object", properties: {
                            campaign_name: schema.properties["campaign_name"],
                            account: schema.properties["account"],
                            lead_linkedin_url: schema.properties["lead_linkedin_url"],
                            campaign_start: schema.properties["campaign_start"]
                        }
                    }} />
                <Text fontSize="xs" fontWeight={900} mb="-8px">Steps</Text>
                {campaignData.messaging_steps.map((step, i) => (
                    <HStack border="1px solid lightgray" p="2px" m="4px" borderRadius={8} spacing={0}>
                        <Stack flexGrow={1} >
                            <AutoUI key={i} value={step}
                                onValueChange={(val) => {
                                    setCampaignData({ ...campaignData, messaging_steps: campaignData.messaging_steps.map((s, j) => i == j ? val : s) })
                                }}
                                schema={
                                    {
                                        type: "object", properties: (schema.properties["messaging_steps"].items as any).properties
                                    }
                                } />
                        </Stack>
                        <CloseButton alignSelf="start" onClick={() => {
                            setCampaignData({ ...campaignData, messaging_steps: campaignData.messaging_steps.filter((s, j) => i != j) })
                        }} />
                    </HStack>
                ))}
                <Button size="xs"
                    onClick={() => {
                        setCampaignData({ ...campaignData, messaging_steps: [...campaignData.messaging_steps, {}] })
                    }}
                >Add step</Button>
                <Button
                    alignSelf={"start"}
                    size="sm"
                    onClick={() => {

                        chatService.sendCommand({ "type": "command", "action_id": "create_campaign", "args": { "_campaign": campaignData } }, { waitForResult: true, timeoutSec: 60 }).then(() => {
                            onClose()
                        })
                    }}
                    colorScheme="brand">Create</Button>

            </Stack>
        </Stack>
    );

}

interface ListOp {
    id: string
    name: string
    kwargs: any
    op_type: string
    arg_schema: JSONSchema
    category: "import" | "transform" | "filter" | "add_column" | "other"
    stages?: { name: string, args: any, args_schema }[]
    state: "init" | "running" | "paused" | "stopped" | "done" | "error"
    state_message: string
}

export default function ListEditPage() {



    const navigate = useNavigate()
    const { listId, listVersion } = useParams()

    const [searchParams, setSearchParams] = useSearchParams()
    const [pageNo, setPageNo] = useState(1)
    useEffect(() => {
        if (listRequest?.data?.page && listRequest?.data?.page != pageNo) {
            setPageNo(listRequest?.data?.page)
        }
    }, [pageNo])
    const listRequest = useApiFetch<DataListInfo>(`/lists/${listId}`, {
        queryArgs: { page: pageNo, version: listVersion },
        shouldFetch: !!listId && listId != "new", swrOptions: { revalidateOnFocus: false, refreshInterval: 0 }
    })
    const [modal, setModal] = useState<any>()

    const [currentOp, setCurrentOp] = useState<any>()
    const [statusMessage, setStatusMessage] = useState<{ status_type: string, message?: string }>(undefined)
    const [listPreviewAwaitingApproval, setListPreviewAwaitingApproval] = useState<ListPreview>()
    const [availableCommands, setAvailableCommands] = useState<{
        add_column?: CommandSectionDescription,
        filter?: CommandSectionDescription,
        others?: CommandSectionDescription
    }>()
    const [filters, setFilters] = useState<{ [key: string]: any }>()
    const _data = listRequest?.data?.data_page

    const [data, setData] = useState<{ [key: string]: any }[]>()
    useEffect(() => { setData(_data) }, [_data])




    const [listName, setListName] = useState<string>()

    const refreshData = listRequest.mutate
    const [pendingUpdate, setPendingUpdate] = useState<any[]>()
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([])
    const [chatService, _setChatService] = useState<ChatService>()
    const [interrupting, setInterrupting] = useState<boolean>()
    const [hidePreview, setHidePreview] = useState<boolean>()
    const [hiddenColumnsDefault, setHiddenColumnsDefault] = useState<string[]>()
    const [columnOrderOverride, _setColumnOrderOverride] = useState<string[]>()
    const [currentListData, setCurrentListData] = useState<DataListInfo>()

    const reorderProperties = (columnSchema, columnOrderOverride) => {
        if (!columnSchema || !columnSchema.properties || !Array.isArray(columnOrderOverride)) {
            return columnSchema;
        }
        const { properties } = columnSchema;
        const reorderedProperties = {};
        columnOrderOverride.forEach((column) => {
            if (properties.hasOwnProperty(column)) {
                reorderedProperties[column] = properties[column];
            }
        });

        Object.keys(properties).forEach((key) => {
            if (!reorderedProperties.hasOwnProperty(key)) {
                reorderedProperties[key] = properties[key];
            }
        });

        return {
            ...columnSchema,
            properties: reorderedProperties,
        };
    };


    useEffect(() => {
        if (listRequest.data) {
            setListName(listRequest.data.name)
            setCurrentListData(listRequest.data)
        }
        else if (currentListData) {
            setCurrentListData(undefined)

        }
    }, [listRequest.data])
    useEffect(() => { currentListData?.filter && setFilters(currentListData.filter) }, [currentListData?.filter])
    const [columnsSchema, setColumnsSchema] = useState<JSONSchema>()
    useEffect(() => {
        if (currentListData?.column_schema) {
            if (columnOrderOverride) {
                setColumnsSchema(reorderProperties(currentListData?.column_schema, columnOrderOverride))
            }
            else {
                setColumnsSchema(currentListData?.column_schema)
            }
        }
    }, [currentListData?.column_schema, columnOrderOverride])

    function updateCurrentListData(dataUpdate: any[], schema?: JSONSchema) {
        if (currentListData) {
            if (!Array.isArray(dataUpdate)) return;


            let newCurrentListData = [...currentListData.data_page]
            let changed = false
            // Iterate over each object in the dataUpdate array
            dataUpdate.forEach(updateObj => {
                // Check if the updateObj has an 'index' property
                if (updateObj && updateObj.index !== undefined) {
                    // Find the object in data_page with the same index
                    const index = updateObj.index;
                    const existingObjArrayIndex = newCurrentListData.findIndex(obj => obj.index === index);
                    const existingObj = newCurrentListData[existingObjArrayIndex];

                    if (existingObj) {
                        // Update the object as a patch
                        Object.assign(existingObj, updateObj);
                        changed = true
                    }
                    else if (newCurrentListData.length < 100) {
                        newCurrentListData.push(updateObj);
                    }


                }
            });
            if (changed) {
                setCurrentListData({ ...currentListData, data_page: newCurrentListData, column_schema: schema || currentListData.column_schema })
            }
        }
    }


    function setChatService(service: ChatService) {
        if (service != chatService) {
            _setChatService(service)
        }

    }
    function showVersions() {
        setModal(<InfoModal caption="Versions" onClose={() => { setModal(undefined) }}>
            <ListVersions listId={listId} />
        </InfoModal>)
    }
    useEffect(() => {
        if (currentListData) {

            let _newHiddenCols = hiddenColumns ? [...hiddenColumns] : []

            currentListData.columns.filter((col) => col.default_hidden && !hiddenColumns.includes(col.name)).forEach((col) => {
                _newHiddenCols.push(col.name)
            })
            setHiddenColumnsDefault(_newHiddenCols)
            setHiddenColumns(_newHiddenCols)
        }
    }, [currentListData])

    const { data: initStatusData, isLoading: initIsLoading, mutate: refreshInitStatus } = useSWR<any>(`/lists/${listId}/status/${!!chatService}`, () => {
        if (chatService && !listVersion) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(chatService.sendCommand({ "type": "command", "action_id": "hello", "args": {} }, { waitForResult: true }))
                }, 5000)
            })

        }
    }
        , { revalidateOnFocus: false, refreshInterval: 0 })

    useEffect(() => {
        if (initStatusData) {


            setCurrentOp(initStatusData.current_op)

                    setAvailableCommands(initStatusData.commands)
                    setFilters(initStatusData.filters)
            setScheduledOps(initStatusData.scheduled_ops)
            setExecutorState(initStatusData.executor_state)


        }
    }, [initStatusData])

    function callCommand(commandId: string, args: any, timeout: number = 120) {
        setCurrentOp(true)
        chatService.sendCommand({ "type": "command", "action_id": commandId, "args": args }, { waitForResult: true, returnResultMessage: true, timeoutSec: timeout }).then((res) => {
            setCurrentOp(false)
            if (res.error) {
                toast({
                    title: "Error executing command",
                    description: res.error,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            else if (res.result.widget_type) {
                if (commandId == "Create campaign") {
                    setModal(<InfoModal caption={res.result.title} onClose={() => { setModal(undefined) }}>
                        <CampaignForm campaign={res.result.data} schema={res.result.data_schema} chatService={chatService} onClose={() => {
                            setModal(undefined)
                        }} />
                    </InfoModal>)
                }
                else {

                    setModal(<InfoModal caption={res.result.title} onClose={() => { setModal(undefined) }}>
                        <WidgetContainer widget={res.result} isLastMessage={true} onExecuteAction={(actionId, val) => {
                            if (res.result.actions?.length && res.result.actions[0].args) {
                                callCommand(actionId, val)

                            }
                        }} />
                </InfoModal>)
                }
            }
            else {

                setModal(undefined)
            }
            console.log(res)
        }).catch((err) => {
            console.error(err)
            setCurrentOp(false)
        })
    }
    function showCommandDialog(section: "add_column" | "filter" | "others", commandId: string) {
        //if (availableCommands[section] && availableCommands[section][commandId] && availableCommands[section][commandId].schema?.properties && Object.keys(availableCommands[section][commandId].schema?.properties)?.length) {
        if ((!["Create campaign", "Edit list metadata", "Get list stats", "Copy list to account", "Versions"].includes(commandId))) {
                setModal(<ListOpDialog
                    opType={commandId}
                    chatService={chatService}
                    interactive={availableCommands[section][commandId].interactive}
                    supports_prompting={availableCommands[section][commandId].supports_prompting}
                    supports_preview={availableCommands[section][commandId].supports_preview}
                    argSchema={availableCommands[section][commandId].schema}
                    onClose={() => {
                        setModal(undefined)
                        setCurrentOp(false)
                        setListPreviewAwaitingApproval(undefined)
                    }}


                />)
            }
            else
            setModal(<EditModal caption={commandId} value={{}} onOk={(args) => {
                callCommand(commandId, args)
            }}
                onCancel={() => {
                    setModal(undefined)
                }}
            >
                {(val, setVal) => (

                    <AutoUI value={val} onValueChange={setVal} schema={availableCommands[section][commandId].schema} />
                )}
            </EditModal>)
        // }
        // else {
        //     callCommand(commandId, {})

        // }
    }
    const tableBackgroundCss = `
    repeating-linear-gradient(
        to right,
        transparent,
        transparent 250px,
        #c8c8c85f 250px,
        #c8c8c85f 251px
    ),
    repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 30px,
        #c8c8c8a0 30px,
        #c8c8c8a0 31px,
        #ffffffa1 32px,
        #ffffffa1 61px,
        #c8c8c8a0 62px,
        #c8c8c8a0 63px
    ),
    linear-gradient(-45deg, white,#eaeaeaa0);
    `

    const restoreVersionEndpoint = useApiEndpoint<any>("POST", `/lists/${listId}/versions/${listVersion}/restore`, true, true)
    const exportEndpoint = useApiEndpoint<Blob>("GET", `/lists/${listId}/export?format={format}`, true, true, { outputType: "blob" })
    function exportList(format: "csv" | "xlsx" | "json") {
        exportEndpoint.execute({ format: format }).then((data) => {
            exportEndpoint.execute({ format: format }).then(blob => {

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `list-${listId}.${format}`;
                a.click();
            })
        })
    }
    const toast = useToast()
    const [isSaving, setIsSaving] = useState(false)
    function save() {
        if (pendingUpdate && data) {
            let deletedItems = data.filter((item, i) => !pendingUpdate.find(c => c.index === item.index))
            let patch = pendingUpdate.map((item, i) => {
                if (!data.find(c => JSON.stringify(c) === JSON.stringify(item))) {
                    return item
                }
            }).filter((item) => item !== undefined)
            console.log("patch", patch)
            console.log("delete", deletedItems)
            if (patch.length || deletedItems.length) {
                setIsSaving(true)
                chatService.sendCommand({ "type": "command", "action_id": "update-data", "args": { "patch": patch, "delete": deletedItems, "page": pageNo } }, { waitForResult: true }).then((res) => {
                    setData(res)
                    setIsSaving(false)
                    setPendingUpdate(undefined)
                }).catch((err) => {
                    toast({
                        title: "Error updating list",
                        description: "An error occurred while updating the list, please reload the page and try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,

                    })
                })
            }
        }
        if (listName != listRequest.data.name) {
            saveMetadata({ name: listName })
        }
    }
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (pendingUpdate && data) {
    //             save()
    //         }
    //     }, 2000)
    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [pendingUpdate])

    const [executorState, setExecutorState] = useState<"running" | "ready" | "paused">()
    const [scheduledOps, setScheduledOps] = useState<ListOp[]>()


    function findAvailableCommand(op_type: string) {
        if (availableCommands) {
            for (let section of Object.keys(availableCommands)) {
                if (availableCommands[section][op_type]) {
                    return availableCommands[section][op_type]
                }
            }
        }
    }

    const setColumnOrderOverride = (newOrder) => {

        _setColumnOrderOverride(newOrder)

    }

    function saveMetadata(payload: { name?: string, list_category?: string, change_column_visibility?: { [colName: string]: boolean } }) {
        chatService.sendCommand({ "type": "command", "action_id": "update-metadata", "args": payload }, { waitForResult: true }).then((res) => {
            if (payload.change_column_visibility) {
                setHiddenColumnsDefault(Object.keys(payload.change_column_visibility).filter((colName) => !payload.change_column_visibility[colName]))
            }
            if (payload.name) {
                listRequest.mutate({ ...listRequest.data, name: payload.name })
            }
        })
    }

    return (<Flex className="page" direction="column" align="stretch" flexGrow={1} justify="stretch" >

        <HStack justify="space-between">
            <HStack>

                {!listName ? <Text>Loading...</Text> :
                    <Editable value={listName} ml="10px">
                        <EditablePreview />
                        <EditableInput onChange={(e) => setListName(e.target.value)} />
                    </Editable>}
                {(pendingUpdate || listName != listRequest.data?.name) && <ButtonGroup>
                    <Button isLoading={isSaving} onClick={() => save()} size="sm" colorScheme="brand" >Save</Button>
                    <Button isLoading={isSaving} onClick={() => {
                        setPendingUpdate(undefined)
                        setListName(listRequest.data.name)
                    }} size="sm" colorScheme="blackAlpha" >Undo</Button>
                </ButtonGroup>
                }
            </HStack>
            {initIsLoading && <Spinner size="sm" />}
            {(currentOp || statusMessage?.status_type == "typing") && <HStack height="40px" p="10px">
                <Spinner size="sm" />

                {!statusMessage?.message && <Text>
                    {typeof (currentOp) === "string" ? currentOp : "Processing..."}
                </Text>}
                {(statusMessage) && <Stack p="10px" justify="stretch" minW="400px">
                    <GeniouslyThemeProvider>
                        <ChatStatusBadge statusMessage={statusMessage} />
                    </GeniouslyThemeProvider>

                </Stack>}
                {<Button size="xs"
                            isLoading={interrupting}
                            onClick={() => chatService.sendCommand({ "type": "command", "action_id": "stop-process", "args": {} }, { waitForResult: true, returnResultMessage: true, timeoutSec: 120 }).then((res) => {
                                setInterrupting(false)

                                if (!res.success) {
                                    toast({
                                        title: "Interruption failed",

                                        status: "error",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                } else {
                                    refreshInitStatus()
                                }
                            })}
                > Stop </Button>}
            </HStack>}
            <HStack p="15px">

                {!modal && !listVersion && 
                    <ButtonGroup size="sm">
                        {availableCommands?.filter && Object.keys(availableCommands?.filter) && <Menu size="sm">
                        <MenuButton variant="outline"
                            isDisabled={!!listPreviewAwaitingApproval}
                            leftIcon={<IconFilter size="18px" />}
                                colorScheme="brand" as={Button} rightIcon={<IconChevronDown size="15px" />}>
                            Filter
                                {filters && <Tag size="xs" fontSize="xs" p="0px 4px" colorScheme="brand">{Object.keys(filters).length}</Tag>}
                        </MenuButton>
                        <MenuList zIndex={15}>
                                {filters && Object.keys(filters) && <MenuGroup title='Filters'>
                                    {Object.keys(filters).map((filterName) => (
                                        <MenuItem as={Button} border="1px solid gray" p="0px 8px" m="4px 8px" rightIcon={<IconX size="15px" />} justifyContent="space-between" key={filterName} onClick={() => {
                                            let newFilters = { ...filters }
                                            delete newFilters[filterName]
                                            chatService.sendCommand({ "type": "command", "action_id": "delete-filter", "args": { "filter": filterName } }, { waitForResult: true }).then((res) => {

                                                    setFilters(newFilters)
                                                    refreshData()

                                            })
                                        }}>{filterName.replace(/_/g, " ")}</MenuItem>
                                    ))}
                                </MenuGroup>}
                                {availableCommands?.filter && Object.keys(availableCommands?.filter).map((cmdId) => (

                                    <MenuItem justifySelf="start" key={cmdId} onClick={() => showCommandDialog("filter", cmdId)}>{JSON.stringify(availableCommands?.filter[cmdId].name)}</MenuItem>
                            ))}

                        </MenuList>
                    </Menu>}
                    {availableCommands?.add_column && Object.keys(availableCommands?.add_column) && <Menu size="sm">
                        <MenuButton variant="outline"
                            isDisabled={!!listPreviewAwaitingApproval}
                            leftIcon={<IconPlus size="18px" />}
                            colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                            Add column
                        </MenuButton>
                        <MenuList zIndex={15}>
                                {availableCommands?.add_column && Object.keys(availableCommands?.add_column).map((cmdId) => (

                                    <MenuItem key={cmdId} onClick={() => showCommandDialog("add_column", cmdId)}>{availableCommands?.add_column[cmdId].name}</MenuItem>
                            ))}

                        </MenuList>
                    </Menu>}

                        {availableCommands?.others && Object.keys(availableCommands?.others) && <Menu size="sm">
                            <MenuButton variant="outline" as={IconButton}
                                isDisabled={!!listPreviewAwaitingApproval}
                                icon={<IconDotsVertical size="18px" />}
                                colorScheme="brand" />


                            <MenuList zIndex={15}>
                                {availableCommands?.others && Object.keys(availableCommands?.others).map((cmdName) => (

                                    <MenuItem key={cmdName} onClick={() => showCommandDialog("others", cmdName)}>{availableCommands?.others[cmdName].name}</MenuItem>
                                ))}

                                <MenuItem onClick={() => showVersions()}>Versions</MenuItem>
                            </MenuList>

                        </Menu>}

                        <Menu size="sm">
                            <MenuButton as={IconButton}
                                onClick={() => {
                                    setModal(<ConfirmModal caption="Operations" onOk={() => { setModal(undefined) }}>
                                        <ListOpsForm listOps={listRequest?.data.operations} executorState={executorState} scheduledOps={scheduledOps}
                                            onExecutorAction={(action) => {
                                                return chatService.sendCommand({ "type": "command", "action_id": "change-executor-state", "args": { "change_state": action } }, { waitForResult: true }).then(() => {
                                                    refreshData()
                                                    setModal(undefined)
                                                })
                                            }}
                                            onOpAction={(op, action, options) => {
                                                if (action === "rerun") {
                                                    return new Promise((resolve, reject) => {

                                                        chatService.sendCommand({ "type": "command", "action_id": "op-rerun", "args": { "op_id": op.id, "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                            if (res.error || !res.success) {
                                                                toast({
                                                                    title: "Error re-running operation",
                                                                    description: res.error,
                                                                    status: "error",
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                })
                                                            }
                                                            else {
                                                                setModal(undefined)
                                                            }

                                                        })
                                                    })

                                                }
                                                else if (action === "retry") {
                                                    let cmd = findAvailableCommand(op.op_type)
                                                    return new Promise((resolve, reject) => {
                                                        setModal(<ListOpDialog opType={op.op_type} argSchema={op.arg_schema} argValues={op.kwargs} chatService={chatService}
                                                            interactive={cmd.interactive}
                                                            supports_preview={cmd.supports_preview}
                                                            supports_prompting={cmd.supports_prompting}
                                                            onClose={() => {
                                                                setModal(undefined)
                                                                resolve(true)
                                                            }
                                                            } />)
                                                    })
                                                }
                                                else if (action === "cancel") {
                                                    return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, "change_state": action } }, { waitForResult: true }).then(() => {
                                                        refreshData()
                                                        setModal(undefined)
                                                    })
                                                }

                                            }}

                                        />
                                    </ConfirmModal>)
                                }}
                                icon={<HStack m="0px 6px"><IconStack2 size="18px" />{executorState == "running" ? <Spinner size="xs" /> : (executorState == "paused" ? (<IconPlayerPauseFilled size="18px" />) : <></>)}</HStack>}
                            />
                        </Menu>
                    <Menu size="sm">
                        <MenuButton as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                            Export
                        </MenuButton>
                        <MenuList zIndex={15}>
                            <MenuItem onClick={() => exportList("csv")}>Export to csv</MenuItem>
                            <MenuItem onClick={() => exportList("xlsx")}>Export to xlsx</MenuItem>
                            <MenuItem onClick={() => exportList("json")}>Export to json</MenuItem>
                        </MenuList>
                    </Menu>
                        {/* <Button
                        onClick={() => {}}
                        >Share</Button> */}
                        <ColumnsSelector
                            pendingSave={!!columnOrderOverride || (hiddenColumns && hiddenColumnsDefault && hiddenColumnsDefault.length !== hiddenColumns.length && new Set(hiddenColumns) !== new Set(hiddenColumnsDefault))}
                            columns={currentListData?.columns?.map((col) => col.name)}
                            hiddenColumns={hiddenColumns}
                            saveMetadata={saveMetadata}
                            setColumnOrder={setColumnOrderOverride}
                            setHiddenColumns={setHiddenColumns}
                            columnOrder={columnOrderOverride}
                        />


                    </ButtonGroup>} 

                {
                    listVersion && (
                        <ButtonGroup>
                            <Button size="sm"
                                variant="outline"
                                leftIcon={<IconChevronLeft size="15px" />}
                                colorScheme="brand"
                                isDisabled={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    navigate(`/lists/${listId}`)
                                }}
                            >Back to current version</Button>

                            <Button size="sm"
                                colorScheme="brand"
                                isLoading={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    restoreVersionEndpoint.execute({}).then(() => {
                                        navigate(`/lists/${listId}`)
                                    })
                                }}
                            >Restore this version</Button>
                        </ButtonGroup>
                    )}


            </HStack>
        </HStack>
        {!hidePreview && listPreviewAwaitingApproval?.preview_data && < IconButton p="0px 60px" alignSelf="center" size="xs" variant="ghost" aria-label="Hide preview" icon={hidePreview ? <IconChevronUp size="15px" /> : <IconChevronDown size="15px" />} onClick={() => setHidePreview(!hidePreview)} />}
        <GeniouslyThemeProvider>
            {modal}
            {(listRequest.isLoading) ? <Stack m="50px" minHeight="40vh" width="auto"
                align="center" justify={"center"}
                border="1px solid #c8c8c8" borderRadius={"5px"}
            >
                {listRequest.isLoading ? (
                    <Box opacity={0.4}>
                        <Spinner size="xl" />
                    </Box>
                ) : (

                    <Stack direction="column" spacing="10px" width="50%" p="4px" border="c8c8c8" shadow="dark-lg" borderRadius="4px" background="white">


                        <ChatInput placeholderText="What list are we gonna build?" onSend={() => { }} />

                    </Stack>
                )}
            </Stack>
                : (
                    <Stack m="10px" width="auto" align="center" justify={"start"}

                        // background={data?.length ? "white" : tableBackgroundCss}
                    >

                        <Stack border="5px solid #c8c8c8" borderRadius={"5px"} height="calc(100vh - 250px)" width="100%" background={!data?.length ? tableBackgroundCss : "white"}>

                        <AutoUI
                            layout="table"
                                onValueChange={(listPreviewAwaitingApproval || currentOp) ? undefined : (val) => {
                                    setPendingUpdate(val)
                                }}

                                value={pendingUpdate || (!hidePreview && !modal && listPreviewAwaitingApproval?.preview_data ? listPreviewAwaitingApproval?.preview_data : data)}

                                excludeFields={hiddenColumns}
                                schema={listPreviewAwaitingApproval?.columns_schema || columnsSchema ? { type: "array", items: ((!hidePreview && listPreviewAwaitingApproval?.preview_data) ? listPreviewAwaitingApproval?.columns_schema : columnsSchema) } : undefined}
                            />


                        </Stack>
                        {!listPreviewAwaitingApproval && <HStack justify="end" alignSelf="end">
                            <Stack align="end" >
                                <Tag >{100 * (pageNo - 1) + 1}-{100 * (pageNo)}</Tag>
                                <Tag>Total items: {currentListData?.total_count}</Tag>

                            </Stack>
                            <IconButton aria-label="Previous page" icon={<IconChevronLeft />} onClick={() => setPageNo(pageNo - 1)} />
                            <IconButton aria-label="Next page" icon={<IconChevronRight />} onClick={() => setPageNo(pageNo + 1)} />
                        </HStack>}

                        {!listVersion && <Stack direction="column" spacing="0px" width="50%" position="absolute" bottom={data ? 0 : 50}  >



                            <GeniouslyCommander
                                appId={"default"}
                                onSessionChanged={(sessionId) => {
                                    if (sessionId != listId) {
                                        navigate(`/chat/${sessionId}`)
                                    }
                                }}
                                onStatusUpdate={(statusMsg: StatusMessage) => {
                                    if (statusMsg?.status_type != "waiting") {
                                        if (!statusMsg.metadata.preview && statusMsg.metadata.executor_state) {

                                            updateCurrentListData(statusMsg.metadata.update, statusMsg.metadata.schema)
                                            setExecutorState(statusMsg.metadata.executor_state)
                                            setScheduledOps(statusMsg.metadata.scheduled_ops)
                                            setStatusMessage(statusMsg)
                                        }
                                    }                                    
                                    else {
                                        setCurrentOp(false)
                                        setStatusMessage(undefined)
                                        if (!statusMsg.metadata.preview && statusMsg.metadata.executor_state) {

                                            updateCurrentListData(statusMsg.metadata.update, statusMsg.metadata.schema)
                                            setExecutorState(statusMsg.metadata.executor_state)
                                            setScheduledOps(statusMsg.metadata.scheduled_ops)
                                            setStatusMessage(statusMsg)
                                        }
                                    }
                                }}
                                onNewMessageReceived={(msg) => {
                                    setStatusMessage(undefined)
                                    if (msg?.widgets?.length && msg.widgets[0].widget_type == "list_preview") {
                                        setCurrentOp(false)
                                        setListPreviewAwaitingApproval(msg.widgets[0] as any)
                                    }
                                }}

                                onExecuteAction={(actionId, args) => {
                                    if (actionId == "refresh") {
                                        refreshData()
                                        refreshInitStatus()


                                        return Promise.resolve({ success: true })
                                    }

                                }}
                                apiUrl={process.env.REACT_APP_API_URL}
                                sessionId={listRequest?.data?.id}
                                visitorId={"-"}
                                inputPlaceholder={statusMessage ? (statusMessage.message || statusMessage.status_type) : (data?.length ? "What data should we add to this list?" : "What should we do with this data ?")}
                                useChatService={(service) => setChatService(service as ChatService)}
                                collectContext={() =>
                                    Promise.resolve({
                                        localTime: new Date().toString(),
                                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })
                                }
                                authDataRetriever={() => getApi().authorize()}
                            //onExecuteAction={(actionId, args) => onExecuteAction(actionId, args)}
                            />
                        </Stack>}
                    </Stack>

                )}
            {/* <AutoUI layout="table" value={[]} schema={{ type: "object", properties: { name: { type: "string" }, age: { type: "number" } } }} /> */}
        </GeniouslyThemeProvider>
    </Flex>

    )
}



const ListVersions = ({ listId }) => {

    return <Stack>
        <GenericList endpointPath={`/lists/${listId}/versions`} listTitle="Versions" primaryField="version" secondaryFields={["created_at"]} />
    </Stack>

}