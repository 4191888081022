import { useEffect, useMemo, useRef, useState } from "react";
import moment from 'moment-timezone';
import {
  Badge,
  Button,
  HStack,
  Heading,
  IconButton,
  Input,
  Link,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { getApi } from "../../apiService";
import { Integration } from "../../models/dataModel";

import { IconChevronCompactDown, IconChevronCompactUp, IconChevronDown, IconExclamationCircle, IconExclamationMark, IconPlus, IconSettings, IconSquare, IconSquareCheck } from "@tabler/icons-react";
import { useTimezoneSelect } from "react-timezone-select";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";
import IntegrationIcon from "./integrationIcon";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { IconChevronUp } from "@tabler/icons-react";
import { IconSquareRoundedMinus } from "@tabler/icons-react";
import { use } from "echarts";


const LinkedinIntegrationDetail = ({
  integration,
  onCredentialsChange,
}: {
  integration: Integration;
  onCredentialsChange?: (new_credentials: { credentials: any }) => void;
}) => {
  const [modal, setModal] = useState<any>();
  const [, setIsWorking] = useState(false);

  const [integrationState, setIntegrationState] = useState<any>(integration);
  const [credentialsPayload, setCredentialsPayload] = useState<any>();

  const CONNECTOR_ID = "linkedin";

  const { data: integrationData, isLoading: IsLoadingIntegration, mutate } = useApiFetch<Integration>(`/integrations/${CONNECTOR_ID}`)
  const deleteAccountEndpoint = useApiEndpoint<any>("DELETE", "/integrations/{connector}/accounts/{account}", false)
  const patchAccountEndpoint = useApiEndpoint<any>("PATCH", "/integrations/{connector}/accounts/{account}", false)
  const unipileConnectRequest = useApiEndpoint<any>("POST", "/unipile/register/{connector}", true, true)


  function refreshIntegration() {
    setIsWorking(true);

    return getApi()
      .getIntegration(CONNECTOR_ID)
      .then((res) => {
        setIntegrationState(res);
        setIsWorking(false);
        return res;
      });
  }
  // useEffect(() => {
  //     //refreshIntegration();
  //     window.addEventListener('message', handleMessageReceived)
  // }, []);

  function handleMessageReceived(event: MessageEvent) {
    console.log("Received msg", event);
    if (event.type === "FETCH_COOKIES_FULFILLED") {
      let newCookies = credentialsPayload ? { ...credentialsPayload } : {};
      newCookies[(event as any).payload.websiteName] = (
        event as any
      ).payload.cookies;
      setCredentialsPayload(newCookies);
      if (linkedinConnectTimeoutRef.current) {
        clearTimeout(linkedinConnectTimeoutRef.current);
      }
      setLoadingCookies(false);
    }
  }

  const [loadingCookies, setLoadingCookies] = useState<boolean | string>(false);
  const [isAddingNew, setAddingNew] = useState<boolean>(false);
  const linkedinConnectTimeoutRef = useRef<any>();



  const [accountPatch, setAccountPatch] = useState<any>()
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  function connectUnipile(reconnect_account: string = undefined) {
    const followup_uri =
      window.location.origin +
      "/integrations/" +
      CONNECTOR_ID +
      "/connect/callback";
    unipileConnectRequest.execute({ connector: CONNECTOR_ID }, null, { redirect: followup_uri, reconnect_account: reconnect_account }).then(({ url }: any) => {
      let popupWindow = window.open(
        url,
        "_blank",
        "width=650,height=700"
      );
      if (popupWindow) {
        popupWindow &&
          window.addEventListener("message", (event) => {
            console.log("window.addEventListener", event);
            setLoading(false);

            mutate()
          });
        let timer = setInterval(() => {
          if (popupWindow == null || popupWindow.closed) {
            setLoading(false);
            mutate().then((res: any) => {

              // return getIntegration().then((res) => {
              //   console.log("getIntegration", res);
              //   setIntegration(res);
              if (res.active && res.accounts?.length && window.parent) {
                window.parent.postMessage("connected:" + CONNECTOR_ID, "*");
              }
              clearInterval(timer);
            })


            // });
          }
        }, 1000);
      } else {
        toast({
          title: "Popup blocked",
          description:
            "Please allow popups for this site and try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }
  const [accountSettingsEdited, setAccountSettingsEdited] = useState<any>()


  return (isLoading || IsLoadingIntegration) ? (
    <>
      <Spinner />
    </>
  ) : (
    <Stack align="start">

      <Stack direction="row">
        {<IntegrationIcon type={CONNECTOR_ID} />}
        <Heading size="md">{integrationState?.name || CONNECTOR_ID}</Heading>
      </Stack>
      <Stack spacing="10px" m="10px" fontSize="xs">
        <Text>
          LinkedIn allows you to connect, message (and read messages), and
          scrape profiles.
        </Text>

      </Stack>
      {modal}

        <Stack alignSelf="stretch">
          {integrationData?.accounts?.map(account => (

            <Stack
              border="1px solid lightgray"
            rounded="3px"
            p="5px"

          >
            <HStack justify="space-between">
              <HStack>
                {!account.primary && <IconButton
                  size="xs"
                  opacity={0.5}
                  _hover={{ opacity: 1 }}
                  variant="ghost"
                  aria-label="Remove"

                  icon={<IconSquareRoundedMinus size="15px" />}
                  isLoading={deleteAccountEndpoint.runningArgs?.account === account.name}
                  onClick={() =>
                    deleteAccountEndpoint.execute({ "connector": integration.connector, "account": account.name }).then(() => mutate())
                  }
                />}

                <Text fontSize="xs" fontWeight={700} whiteSpace="nowrap">
              Account: {account.name}
            </Text>
                {account.primary ? (
                  <Badge colorScheme="gray">Primary</Badge>
                ) : (
                  <>
                    <Button
                      size="xs"
                      colorScheme="gray"
                      variant="outline"
                      isLoading={patchAccountEndpoint.runningArgs?.account === account.name}
                      onClick={() =>
                        patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                          { primary: true }
                        )
                          .then(() => mutate())
                      }
                    >
                      Set primary
                    </Button>

                  </>
                )}
              </HStack>
            <Stack width="500px">
                {/* <TimeZoneSelect
                value={account.timezone} onChange={(value) => {
                  setAccountPatch({ timezone: value })
                }} /> */}

              {/* placeholder="Select timezone"
              suggestions={options.map((o) => o.label)}
              value={(account as any).settings?.timezone || ""}
              onApply={(e) => setCredentialsPayload({ ...(credentialsPayload || {}), timezone: e.toString() })}
            />} */}
            </Stack>
            <HStack alignSelf="end">
                {account.active ? (


                  account.timezone ? <Text fontSize="xs">{account.timezone}</Text> : <Tag colorScheme="red"><Text mr="8px" whiteSpace="nowrap">{!accountPatch?.timezone ? "No timezone set" : "Pending save"}</Text><IconExclamationCircle size="18px" /></Tag>
                ) : (
                  <Button size="xs" colorScheme="brand" isLoading={unipileConnectRequest.isRunning} onClick={() => {
                    connectUnipile(account.name)
                  }}>Reconnect</Button>
                )}
                {accountPatch && accountSettingsEdited == account.name ? <HStack> <Button size="xs" colorScheme="brand" isLoading={patchAccountEndpoint.isRunning}
                    onClick={() => {
                      patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                        accountPatch
                      )
                        .then(() => {
                          mutate()
                          setAccountPatch(undefined)
                        })
                    }}
                >Save</Button>
                  <Button size="xs"
                    onClick={() => {
                      setAccountPatch(undefined)
                    }}
                  >Cancel</Button>

                </HStack> :
                  <Button size="xs"
                    isDisabled={!!accountPatch}
                    leftIcon={<IconSettings />}
                    rightIcon={accountSettingsEdited ? <IconChevronUp /> : <IconChevronDown />}
                    variant="outline"
                    colorScheme="brand" onClick={() => setAccountSettingsEdited(accountSettingsEdited ? undefined : account.name)}>Settings</Button>}


              <Badge
                cursor="pointer"
                onClick={() => {
                  setModal(<ConfirmModal
                    onCancel={() => setModal(null)}
                    onOk={() => {
                      patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                        { active: !account.active }
                      )
                        .then(() => mutate())
                      setModal(null)
                    }}

                    caption={account.active ? "Deactivate account" : "Activate account"} question={`Do you want to ${account.active ? "deactivate " : "activate account"} ${account.name} account? `} />)
                }} colorScheme={account.active ? "green" : "gray"} ><HStack>{account.active ? <IconSquareCheck color="gray" size="15px" /> : <IconSquare size="15px" />}<Text> {account.active ? "Active" : "Disabled"}</Text></HStack></Badge>
            </HStack>
          </HStack>
            {accountSettingsEdited == account.name && <AccountSettings account={!accountPatch ? account : { ...account, ...accountPatch }} onSettingsChange={(new_settings) => {
              setAccountPatch({ ...(accountPatch || {}), ...new_settings })
            }} />}
          </Stack>
        ))}

        {/* We might want to add custom busters */}
        {/* <Text fontWeight={700}>Custom endpoints as actions</Text> */}

        {/* {integration.actions?.length > 0 && (
          <Stack align="start">
            <HStack align="start">
              <Wrap>
                {integration.actions?.map((action) => (
                  <WrapItem>
                    <Tag
                      border="1px solid gray"
                      rounded="3px"
                      p="0px 5px"
                      backgroundColor="white"
                      cursor="pointer"
                      onClick={() => {
                        openCommandActionDialog(action as any);
                      }}
                    >
                      <IconBolt size="15px" />
                      <Text fontSize="xs" fontWeight={500} whiteSpace="nowrap">
                        {action.action_name}
                      </Text>
                      <TagCloseButton
                        onClick={(e) => {
                          e.stopPropagation();
                          getApi()
                            .deleteIntegrationAction(
                              integration.connector,
                              action.action_id
                            )
                            .then(() => {
                              setModal(null);
                              refreshIntegration();
                              setIsWorking(true);
                            })
                            .catch(() => {
                              setIsWorking(true);
                            });
                        }}
                      />
                    </Tag>
                  </WrapItem>
                ))}
            </Wrap>
        </HStack>
            
          </Stack >
        )}
            </Stack >
            <Button
                leftIcon={<IconPlus />}
                onClick={() => { }}
                size="xs"
            >
                Add custom phantom buster
            </Button>
            <Stack m="10px 0px"></Stack>
    */}
        {/* {cookies && Object.keys(cookies).map((website) => (
                    <Stack>
                        <Text fontWeight={700}>Cookies for {website}</Text>
                        <Text>{JSON.stringify(cookies[website])}</Text>
                    </Stack>
                ))} */}
        {/* {loadingCookies && (
          <Stack fontSize="xs" p="10px" background="gray.100" rounded="lg">
            {typeof loadingCookies == "boolean" && (
              <HStack>
                <Spinner /> <Text>Connecting to PhantomBuster extension</Text>
              </HStack>
            )}
            {typeof loadingCookies == "string" && (
              <HStack>
                <Text>{loadingCookies}</Text>
              </HStack>
            )}
          </Stack>
        )} */}

        {/* {
          <Button
            alignSelf="start"
            leftIcon={<IconBrandLinkedin />}
            colorScheme="linkedin"
            onClick={() => {
              getCookies("LinkedIn");
            }}
            size="xs"
          >
            Connect to LinkedIn
          </Button>
        } */}

          {false ? <Stack border="1px solid" borderColor="brand" borderRadius="8px" p="5px">
          <Stack width="100%" >
            <Text fontWeight={900}>Add LinkedIn account</Text>
            <Text fontSize="xs" fontWeight={900}  >Account name</Text>
            <Input
              size="xs"
              width="100%"
              placeholder="eg. John Doe"
              value={credentialsPayload?.account_name || ""}
              onChange={(e) =>
                setCredentialsPayload({ ...(credentialsPayload || {}), account_name: e.target.value })
              }
            />
            <Text fontSize="xs" fontWeight={900} >Please paste here your LinkedIn coookie</Text>
            <Input
              size="xs"
              width="100%"
              placeholder="eg. AQEDAQmkYSkC1lvJAAABjjhgT8UAAAGO1BMck00AMw5P4DMub3dG_lfJpF_NcJsxNJ8MX86ZWX..."
              value={credentialsPayload?.linkedin_cookie || ""}
              onChange={(e) =>
                setCredentialsPayload({ ...(credentialsPayload || {}), linkedin_cookie: e.target.value })
              }
              />

            <Text fontSize="xs" fontWeight={900}>Choose timezone for this account</Text>
              <Stack fontSize="xs">
                {/* <TimeZoneSelect
                  value={credentialsPayload?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
                  onChange={(value) => {
                    setCredentialsPayload({ ...(credentialsPayload || {}), timezone: value })
                  }} /> */}
                {/* <TimezoneSelect
                  labelStyle="abbrev"
                  value={credentialsPayload?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
                  onChange={(e) => setCredentialsPayload({ ...(credentialsPayload || {}), timezone: (e as any).value })}
            /> */}
              </Stack>
          </Stack>
          <Stack width="100%">
            <Stack spacing={0}>

              <Text fontSize="xs" fontWeight={900} >
                User agent of the browser
              </Text>
              <Text fontSize="xs" >(If you've signed in to LinkedIn from a different browser, enter the correct user agent here instead)</Text>
            </Stack>
            <Input
              size="xs"
              width="100%"
              placeholder="eg. Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36"
              value={credentialsPayload?.user_agent || navigator.userAgent}
              onChange={(e) =>
                setCredentialsPayload({
                  ...(credentialsPayload || {}),
                  user_agent: e.target.value,
                })
              }
            />
            <Text as="i" fontSize={12}>
              Disclaimer: If the user agent doesn't match the browser where you signed in to linkedin, you will likely need to sign into linkedin again and repeat this process.
            </Text>
            <HStack justify="end">
              {isAddingNew && <Button
                alignSelf="start"
                variant="outline"
                colorScheme="brand"
                onClick={() => {
                  setAddingNew(false)
                }}
                size="xs"
              >
                Cancel
              </Button>}
              <Button
                alignSelf="start"
                  isLoading={unipileConnectRequest.isRunning}
                colorScheme="brand"
                onClick={() => {
                  unipileConnectRequest.execute({ connector: CONNECTOR_ID },
                    {
                      account_name: credentialsPayload.account_name,
                      timezone: credentialsPayload.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
                      credentials: {
                        linkedin_cookie: credentialsPayload.linkedin_cookie,
                        user_agent: (credentialsPayload.user_agent || navigator.userAgent),
                      }
                    }).then(() => {
                      mutate()
                      setAddingNew(false)
                    })
                }}
                size="xs"
              >
                Confirm
              </Button>

            </HStack>
          </Stack>
        </Stack> : (
              <HStack>
                <Stack spacing={2}>
                  <Button
            alignSelf="start"
            leftIcon={<IconPlus />}
            colorScheme="brand"
            onClick={() => {
              //setAddingNew(true)
              connectUnipile()

            }}
            size="xs"
                  >
                    Add new account
                  </Button>
                  <Text fontSize="xs">
                    👉 You can also use cookies to login... you can use{" "}
                    <Link
                      isExternal
                      color="blue"
                      fontWeight={900}
                      href="https://chromewebstore.google.com/detail/mailmatics-cookie-grabber/icmafmichnkancjkggpclecfjiklibjh"
                    >
                      this chrome extension
                    </Link>
                    {" to grab it"}
                  </Text>
                </Stack>
              </HStack>

        )}
      </Stack>
    </Stack>
  );
};


const AccountSettings = ({ account, onSettingsChange }: { account: any, onSettingsChange: (new_settings: any) => void }) => {
  return <Stack>
    <Text fontSize="xs" fontWeight={900}>Timezone</Text>
    <TimeZoneSelect value={account.timezone} onChange={(val) => {
      onSettingsChange({ timezone: val })
    }} />
    <Text fontSize="xs" m="10px 0px -10px" fontWeight={900}>Daily connection request limit</Text>
    <SliderWithMarks value={account.settings?.limits?.daily_connection_requests || 10}
      marks={[0, 2, 10, 20, 28, 50]}
      limits={[
        { limit: 0, color: "lightgreen", info: "For new accounts" },
        { limit: 2, color: "green", info: "Safe zone" },
        { limit: 10, color: "green", info: "For active accounts / Paid accounts" },
        { limit: 20, color: "orange", info: "For active accounts / Paid accounts" },
        { limit: 28, color: "red", info: "Danger" },
        { limit: 50, color: "red", info: "Danger" }
      ]}
      onChange={(val) => {
        onSettingsChange({ settings: { ...(account.settings || {}), limits: { ...(account.settings?.limits || {}), daily_connection_requests: val } } })
      }} />
    <Text fontSize="xs" m="10px 0px -10px" fontWeight={900}>Daily messages limit</Text>
    <SliderWithMarks value={account.settings?.limits?.daily_messages_requests || 100}
      marks={[0, 50, 100, 150, 200, 300, 450]}
      limits={[
        { limit: 0, color: "green", info: "For new accounts" },
        { limit: 50, color: "green", info: "Safe zone" },
        { limit: 101, color: "green", info: "For active accounts" },
        { limit: 150, color: "green", info: "For active accounts" },
        { limit: 200, color: "orange", info: "Risky" },

        { limit: 500, color: "red", info: "Danger" }
      ]}
      onChange={(val) => {
        onSettingsChange({ settings: { ...(account.settings || {}), limits: { ...(account.settings?.limits || {}), daily_messages_requests: val } } })

      }} />
  </Stack>
}

function SliderWithMarks({ marks, markUnit, limits, value, onChange
}: {
  value: number,
  onChange: (value: number) => void,
  marks?: number[],
  markUnit?: string,
  limits?: {
    limit: number,
    color: string
    info: string
  }[]

}) {
  const [sliderValue, setSliderValue] = useState(value || 0)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const currentLimit = useMemo(() => {
    if (!limits) return
    let limitsReversed = [...limits].reverse()
    return limitsReversed?.find(l => sliderValue > l.limit) || limits[0];

  }, [sliderValue, limits])

  useEffect(() => setSliderValue(value), [value])

  const min = limits?.length ? limits[0].limit : 0
  const max = limits?.length ? limits[limits.length - 1].limit : 100
  return (
    <Stack p="0px 20px 10px" spacing={0}>
      <Text fontWeight={600} color="gray.600" fontSize="xs" textAlign={sliderValue > (min + ((max - min) / 2)) ? "start" : "end"}>{currentLimit?.info}</Text>
      <Slider
        id='slider'
        value={sliderValue}
        min={min}
        max={max}

        onChange={(v) => setSliderValue(v)}
        onBlur={() => onChange(sliderValue)}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}

      >
        {marks?.map((mark) => (
          <SliderMark value={mark} mt='1' ml='-2.5' fontSize='sm' fontWeight={700} color="blackAlpha.500">
            {mark}{markUnit}
          </SliderMark>
        ))}

        <SliderTrack>
          <SliderFilledTrack backgroundColor={currentLimit?.color || "teal"} />
        </SliderTrack>
        <Tooltip

          hasArrow
          bg="blackAlpha.800"
          color='white'
          placement='top'
          isOpen={tooltipOpen}
          label={`${sliderValue}${markUnit || ""}`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    </Stack>
  )
}
const TimeZoneSelect = ({ value, onChange }: { value: string, onChange: (value: any) => void }) => {
  const { options: optionsFallback, parseTimezone } = useTimezoneSelect({ labelStyle: "abbrev" })
  const timezoneOptions = useMemo(() => {
    const timezones = moment.tz.names();
    return timezones.map(tz => {
      const offset = moment.tz(tz).utcOffset() / 60;
      const sign = offset >= 0 ? '+' : '-';
      const formattedOffset = `GMT${sign}${Math.abs(offset).toString().padStart(1, '0')}`;
      return { label: `${tz.replace(/_/g, ' ')} (${formattedOffset})`, value: tz };
    })
  }, [])


  // const getTimeZonesWithOffsetAndAbbreviation = () => {
  //   const timeZones = (Intl as any).supportedValuesOf && (Intl as any).supportedValuesOf('timeZone'); // Use the built-in method to get supported time zones
  //   if (timeZones) return optionsFallback;
  //   const now = new Date();

  //   return timeZones.map(timeZone => {
  //     const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  //       timeZone,
  //       hour: '2-digit',
  //       minute: '2-digit',
  //       second: '2-digit',
  //       timeZoneName: 'short'
  //     });

  //     const parts = dateTimeFormat.formatToParts(now);
  //     const timeZoneName = parts.find(part => part.type === 'timeZoneName').value;

  //     // Calculate GMT offset
  //     const options = { timeZone, hour12: false, hour: '2-digit', minute: '2-digit' } as any;
  //     const dateTime = new Intl.DateTimeFormat('en-US', options).formatToParts(now);
  //     const [hours, minutes] = dateTime.filter(part => part.type === 'hour' || part.type === 'minute').map(part => parseInt(part.value, 10));

  //     const gmtOffset = -(hours * 60 + minutes);

  //     return {
  //       timeZone,
  //       gmtOffset,
  //       abbreviation: timeZoneName
  //     };
  //   });
  // };
  return <AutoComplete openOnFocus

    value={typeof (value) == "string" ? value : undefined} onChange={onChange}>
    <AutoCompleteInput size="xs" placeholder="Select timezone" />


    <AutoCompleteList>
      {timezoneOptions.map((v, cid) => (
        <AutoCompleteItem
          fontSize="sm"
          key={`option-${cid}`}
          value={v.value}

          _selected={{ bg: "whiteAlpha.50" }}
          _focus={{ bg: "whiteAlpha.100" }}
        >
          {v.label}
        </AutoCompleteItem>
      ))}
    </AutoCompleteList>
  </AutoComplete>
}

export { LinkedinIntegrationDetail };
